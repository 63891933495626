const config = {
	"gatsby": {
		"pathPrefix": "/",
		"siteUrl": "https://launch.cwkosman.dev",
		"gaTrackingId": "UA-130314353-3",
		"trailingSlash": false
	},
	"header": {
		"logo": "https://cdn.shopify.com/assets2/brand-assets/glyph-sample-square-c19c11377a93759183e4b05084ea0a2d63b74b26bc96e0d9f73f5173bbb155e2.svg",
		"logoLink": "https://help.shopify.com",
		"title": "Launch Documentation",
		"githubUrl": "https://github.com/cwkosman/launch-welcome-kit",
		"helpUrl": "",
		"tweetText": "",
		"links": [
			{ "text": "", "link": ""}
		],
		"search": {
			"enabled": false,
			"indexName": "",
			"algoliaAppId": process.env.GATSBY_ALGOLIA_APP_ID,
			"algoliaSearchKey": process.env.GATSBY_ALGOLIA_SEARCH_KEY,
			"algoliaAdminKey": process.env.ALGOLIA_ADMIN_KEY
		}
	},
	"sidebar": {
		"forcedNavOrder": [
			"/introduction",
			"/plan",
			"/implement",
			"/test",
			"/launch"
			// add trailing slash if enabled above
		],
    	"collapsedNav": [
      	// add trailing slash if enabled above
    	],
		"links": [
			{ "text": "Shopify Help Center", "link": "https://help.shopify.com/"},
			{ "text": "Shoipfy Developer Docs", "link": "https://developers.shopify.com/"},
			{ "text": "Partner Academy", "link": "https://www.shopify.ca/partners/academy"}
		],
		"frontline": false,
		"ignoreIndex": true,
	},
	"siteMetadata": {
		"title": "Launch Welcome Kit",
		"description": "Protoype of Shopify Launch Welcome Kit",
		"ogImage": null,
		"docsLocation": "https://github.com/cwkosman/launch-welcome-kit/tree/master/content",
		"favicon": "https://screenshot.click/20-07-piye4-us5k8.jpg"
	},
	"pwa": {
		"enabled": false, // disabling this will also remove the existing service worker.
		"manifest": {
			"name": "Gatsby Gitbook Starter",
			"short_name": "GitbookStarter",
			"start_url": "/",
			"background_color": "#6b37bf",
			"theme_color": "#6b37bf",
			"display": "standalone",
			"crossOrigin": "use-credentials",
			icons: [
				{
					src: "src/pwa-512.png",
					sizes: `512x512`,
					type: `image/png`,
				},
			],
		},
	}
};

module.exports = config;
